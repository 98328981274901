import Vue from "vue";
import Vuex from "vuex";
import { nuevo_mensajes_inmediato, guardaArchivo } from "../bd_ws.js";
import moment from "moment";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseDatos: [],
    esmovil: "",
    logoempresa: "",
    logoempresa_agua: "",
    permisos: [],
    clientes: [],
    productos: [],
    monturas: [],
    lentes_c: [],
    configuracion: [],
    configImpresora: {},
    seriesdocumentos: {},
    dialogocontadores: null,
    dialogomedidas: null,
    dialogoFecha: null,
    dialogoFecha2: null,
    dialogoprogress: null,
    modo_progress: null,
    dialogoBloqueo: null,
    dialogosnackbar: null,
    activa_pagos: false,
    abre_qr: false,
    array_paciente: [],
    array_empleados: [],
    tabla_empleados: [],
    array_proforma: [],
    array_tiendas: [],
    tienda_actual: "",
    array_periodos: [
      "03-2025",
      "02-2025",
      "01-2025",
      "12-2024",
      "11-2024",
      "10-2024",
      "09-2024",
      "08-2024",
    ],
    tratamiento: [
      "U.V",
      "ANTIRREFLEJO",
      "FILTRO LUZ AZUL",
      "FOTOSENSIBLE",
      "FOTOSENSIBLE + FILTRO LUZ AZUL",
    ],
    tipo_focal: [
      "MULTIFOCAL CONVENCIONAL",
      "MULTIFOCAL DIGITAL",
      "MONOFOCAL DIGITAL",
      "MONOFOCAL STANDAR",
      "BIFOCAL CONVENCIONAL",
      "POLARIZADO",
      "LENTES DE CONTACTO",
    ],
    tipo_material: ["RESINA", "CRISTAL", "POLICARBONATO"],
    tipo_material_montura: ["ACETATO", "METAL", "CAREI", "SILICONA"],
    tipo_montura: ["ARO COMPLETO", "SEMI AL AIRE", "AL AIRE"],
    tipo_pago: ["EFECTIVO", "TARJETA", "YAPE", "PLIN", "TRANSFERENCIA"],
    tiendas: [
      "MERCADERES",
      "GRUPO",
      "SAN AGUSTIN",
      "GAMARRA",
      "KVISION",
      "VIRREY",
      "ESPANA",
    ],
    medidas: [
      "UNIDAD",
      "DOCENA",
      "PAR",
      "MILLARES",
      "KILOGRAMO",
      "METRO",
      "GRAMO",
      "PAQUETE",
      "CAJA",
      "BOTELLAS",
      "LITRO",
      "GALON",
      "BIDON",
      "PLANCHA",
      "CIENTO",
    ],
    array_filtro: [
      "GAMA CONVENCIONAL",
      "GAMA STANDAR",
      "GAMA SILVER",
      "GAMA PREMIUN",
      "MONOFOCAL DIGITAL",
      "MONOFOCAL STANDAR",
      "BIFOCAL CONVENCIONAL",
      "POLARIZADO",
    ],
    medidassunat: [
      {
        nombre: "UNIDAD",
        corto: "NIU",
        general: "UND",
      },
      {
        nombre: "DOCENA",
        corto: "DZN",
        general: "DOC",
      },
      {
        nombre: "PAR",
        corto: "NIU",
        general: "PAR",
      },
      {
        nombre: "MILLARES",
        corto: "MIL",
        general: "MILL",
      },
      {
        nombre: "KILOGRAMO",
        corto: "KGM",
        general: "KG",
      },
      {
        nombre: "METRO",
        corto: "MTR",
        general: "MT",
      },
      {
        nombre: "GRAMO",
        corto: "GRM",
        general: "GR",
      },
      {
        nombre: "PAQUETE",
        corto: "PK",
        general: "PAQ",
      },
      {
        nombre: "CAJA",
        corto: "BX",
        general: "CAJ",
      },
      {
        nombre: "BOTELLAS",
        corto: "BO",
        general: "BOT",
      },
      {
        nombre: "LITRO",
        corto: "LTR",
        general: "LT",
      },
      {
        nombre: "GALON",
        corto: "GLI",
        general: "GAL",
      },
      {
        nombre: "BIDON",
        corto: "NIU",
        general: "BID",
      },
      {
        nombre: "PLANCHA",
        corto: "NIU",
        general: "UND",
      },
      {
        nombre: "CIENTO",
        corto: "NIU",
        general: "UND",
      },
    ],
    corredores: [
      {
        nombre: "ADAPTA VIEW",
        valor: [16, 17, 18, 19, 20],
      },
      {
        nombre: "EASY VIEW",
        valor: [14, 15, 16, 17, 18, 19, 20],
      },
      {
        nombre: "VIFIT",
        valor: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
      },
      {
        nombre: "TOP",
        valor: [16, 17, 18, 19, 20],
      },
      {
        nombre: "ULTRAVIEW",
        valor: [14, 15, 16, 17, 18],
      },
      {
        nombre: "SIGNATURE",
        valor: [14, 15, 16, 17, 18, 19, 20],
      },
      {
        nombre: "KODAK PRECISE",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
      {
        nombre: "KODAK EASY",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
      {
        nombre: "KODAK UNIQUE",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
      {
        nombre: "VARILUX CONFORT MAX",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
      {
        nombre: "VARILUX PHYSIO 3.0",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
      {
        nombre: "VARILUX X SERIES",
        valor: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
      },
    ],
    motivos: [
      "Anulación de la operación",
      "Anulación por error en el RUC",
      "Corrección por error en la descripción",
      "Descuento global",
      "Descuento por ítem",
      "Devolución total",
      "Devolución por ítem",
      "Bonificación",
      "Disminución en el valor",
      "Otros Conceptos",
    ],
    motivosSunat: [
      {
        nombre: "Anulación de la operación",
        codigo: "01",
      },
      {
        nombre: "Anulación por error en el RUC",
        codigo: "02",
      },
      {
        nombre: "Corrección por error en la descripción",
        codigo: "03",
      },
      {
        nombre: "Descuento global",
        codigo: "04",
      },
      {
        nombre: "Descuento por ítem",
        codigo: "05",
      },
      {
        nombre: "Devolución total",
        codigo: "06",
      },
      {
        nombre: "Devolución por ítem",
        codigo: "07",
      },
      {
        nombre: "Bonificación",
        codigo: "08",
      },
      {
        nombre: "Disminución en el valor",
        codigo: "09",
      },
      {
        nombre: "Otros Conceptos",
        codigo: "10",
      },
    ],
    grupos: [],
    empleados: [],
    emojis: [
      "😀",
      "😁",
      "😂",
      "🤣",
      "😃",
      "😄",
      "😅",
      "😆",
      "😉",
      "😊",
      "😋",
      "😎",
      "😍",
      "😘",
      "🥰",
      "😗",
      "😙",
      "😚",
      "☺️",
      "🙂",
      "🤗",
      "🤩",
      "😏",
      "😌",
      "😛",
      "😜",
      "😝",
      "🤤",
      "😒",
      "😓",
      "😔",
      "😕",
      "🙃",
      "🤑",
      "😲",
      "☹️",
      "🙁",
      "😖",
      "😞",
      "😟",
      "😤",
      "😢",
      "😭",
      "😦",
      "😧",
      "😨",
      "😩",
      "🤯",
      "😬",
      "😰",
      "😱",
      "🥵",
      "🥶",
      "😳",
      "🤪",
      "😵",
      "😡",
      "😠",
      "🤬",
      "😷",
      "🤒",
      "🤕",
      "🤢",
      "🤮",
      "🤧",
      "😇",
      "🤠",
      "🤡",
      "🥳",
      "🥴",
      "🥺",
      "🤥",
      "🤫",
      "🤭",
      "🧐",
      "🤓",
      "😈",
      "👿",
      "👹",
      "👺",
      "💀",
      "👻",
      "👽",
      "🤖",
      "💩",
      "😺",
      "😸",
      "😹",
      "😻",
      "😼",
      "😽",
      "🙀",
      "😿",
      "😾",
    ],
  },
  mutations: {
    grupos(state, n) {
      state.grupos = n;
    },
    empleados(state, n) {
      state.empleados = n;
    },
    BD(state, n) {
      state.baseDatos = n;
      console.log("STORE: " + n.bd);
    },
    esmovil(state, n) {
      state.esmovil = n;
    },
    permisos(state, n) {
      state.permisos = n;
    },
    configuracion(state, n) {
      state.configuracion = n;
    },
    configImpresora(state, n) {
      state.configImpresora = n;
    },
    array_tiendas(state, n) {
      state.array_tiendas = n;
    },
    tienda_actual(state, n) {
      state.tienda_actual = n;
    },
    seriesdocumentos(state, n) {
      state.seriesdocumentos = n;
    },
    abre_qr(state, n) {
      state.abre_qr = n;
    },
    array_proforma(state, n) {
      state.array_proforma = n;
    },
    logoempresa(state, n) {
      state.logoempresa = n;
    },
    dialogoBloqueo(state) {
      state.dialogoBloqueo = !state.dialogoBloqueo;
    },
    logoempresa_agua(state, n) {
      state.logoempresa_agua = n;
    },
    activa_pagos(state, n) {
      state.activa_pagos = n;
    },
    productos(state, n) {
      state.productos = n;
    },
    monturas(state, n) {
      state.monturas = n;
    },
    all_lentes_c(state, n) {
      state.lentes_c = n;
    },
    clientes(state, n) {
      state.clientes = n;
    },
    categorias(state, n) {
      state.categorias = n;
    },
    array_paciente(state, n) {
      state.array_paciente = n;
    },
    array_empleados(state, n) {
      state.array_empleados = n;
    },
    tabla_empleados(state, n) {
      state.tabla_empleados = n;
    },
    configImpresora(state, n) {
      state.configImpresora = n;
    },
    seriesdocumentos(state, n) {
      state.seriesdocumentos = n;
    },
    dialogoprogress(state, n) {
      state.dialogoprogress = !state.dialogoprogress;
      state.modo_progress = n;
    },
    dialogosnackbar(state, n) {
      state.dialogosnackbar = !state.dialogosnackbar;
      state.textosnackbar = n;
    },
  },
  actions: {
    async envio_sms({ commit }, { numero, url }) {
      var fecha = moment().unix();
      var array = {
        url: url,
        estado: "pendiente",
        fecha_crea: fecha,
        fecha_envio: fecha,
        mensaje: "",
        numero: "51" + numero,
      };
      await nuevo_mensajes_inmediato("BD4", array);
    },
    async sube_archivo({ commit }, { selectedFile }) {
      try {
        // Iniciar el diálogo de progreso

        console.log(selectedFile.name);

        // Iniciar la tarea de guardado de archivo
        const task = guardaArchivo("/", selectedFile, selectedFile.name);

        task.on("state_changed", (snapshot) => {
          let porcentaje =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(porcentaje);
        });

        // Esperar a que la tarea termine
        await task;

        // Obtener la URL de descarga
        const url = await task.snapshot.ref.getDownloadURL();
        console.log("exito");
        selectedFile = "";

        return url;
      } catch (error) {
        console.error("Error al subir el archivo:", error.message);
        throw new Error("No se pudo subir el archivo.");
      }
    },
  },
  modules: {},
});
