import { jsPDF } from "jspdf";
import faviconSrc from "@/assets/favicon.png";
import imgLentesSrc from "@/assets/m_lentes.png";
import iconoInfinitoSrc from "@/assets/m_simbolo.png";
import fondoMarcaAguaSrc from "@/assets/fondo_montura.png";
import iconoWhatsappSrc from "@/assets/what.png";

// Cargar imagen como objeto Promise
function loadImage(src) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = src;
    img.onload = () => resolve(img);
  });
}

export const generarPDFMontura = async (
  textoPersonalizado,
  nombrePaciente,
  codigoPedido,
  modo
) => {
  // Cargar todas las imágenes antes de generar el PDF
  const [
    favicon,
    imgLentes,
    iconoInfinito,
    fondoMarcaAgua,
    iconoWhatsapp,
  ] = await Promise.all([
    loadImage(faviconSrc),
    loadImage(imgLentesSrc),
    loadImage(iconoInfinitoSrc),
    loadImage(fondoMarcaAguaSrc),
    loadImage(iconoWhatsappSrc),
  ]);

  const doc = new jsPDF({
    orientation: "portrait",
    unit: "mm",
    format: "A5",
  });

  const hoy = new Date();
  const fechaHoy = hoy.toLocaleDateString();
  const horaHoy = hoy.toLocaleTimeString();

  doc.addImage(favicon, "PNG", 45, 5, 60, 47);
  doc.addImage(imgLentes, "PNG", 10, 40, 130, 50);
  doc.addImage(iconoInfinito, "PNG", 22, 53, 5, 2.5);

  // Dibujo de letras en arco
  doc.setFont("Helvetica", "");
  doc.setFontSize(8);
  doc.setTextColor(0, 0, 0);

  let centerX = 124;
  let centerY = 61;
  let radius = 8;
  let startAngle = -80;
  let endAngle = -40;
  let factor = 0.9;
  let r_fac = 0.25;
  let r_fac1 = 1.5;

  if (textoPersonalizado.length === 3) {
    endAngle = -50;
    factor = 0.7;
  } else if (textoPersonalizado.length === 2) {
    centerX = 126;
    centerY = 61;
    radius = 7;
    endAngle = -50;
    factor = 1;
    r_fac = 0.05;
    r_fac1 = 0.95;
  } else if (textoPersonalizado.length === 1) {
    centerX = 126;
    centerY = 61;
    radius = 7;
    startAngle = -75;
    endAngle = -50;
    factor = 0.75;
    r_fac = 1;
    r_fac1 = 1;
  }

  const totalLetters = textoPersonalizado.length;
  const angleStep = (endAngle - startAngle) / (totalLetters * factor);

  for (let i = 0; i < totalLetters; i++) {
    let angle = (startAngle + i * angleStep) * (Math.PI / 150);
    let x = centerX + radius * Math.cos(angle);
    let y = centerY + radius * Math.sin(angle);
    let rotationFactor = (i + r_fac) / (totalLetters - r_fac1);
    let rotationAngle = rotationFactor * (startAngle - endAngle);
    doc.text(textoPersonalizado[i], x, y, {
      align: "center",
      angle: rotationAngle,
    });
  }

  // 📌 Marca de agua
  doc.addImage(fondoMarcaAgua, "PNG", 10, 100, 130, 90);

  // Información final
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  let baseY = 107;
  const spacing = 6;
  const leftX = 35;

  doc.setFontSize(9);
  doc.setFont("Helvetica", "bold");
  doc.text(`Nombre del paciente:`, leftX, baseY);
  doc.setFont("Helvetica", "");
  doc.text(`${nombrePaciente}`, leftX + 45, baseY);

  doc.setFont("Helvetica", "bold");
  doc.text(`Código de pedido:`, leftX, baseY + spacing);
  doc.setFont("Helvetica", "");
  doc.text(`${codigoPedido}`, leftX + 45, baseY + spacing);

  doc.setFont("Helvetica", "bold");
  doc.text(`Iniciales:`, leftX, baseY + spacing * 2);
  doc.setFont("Helvetica", "");
  doc.text(`${textoPersonalizado}`, leftX + 45, baseY + spacing * 2);

  doc.setFont("Helvetica", "bold");
  doc.text(`Grabado Láser:`, leftX, baseY + spacing * 3);
  doc.addImage(
    iconoInfinito,
    "PNG",
    leftX + 45,
    baseY + spacing * 3.1 - 3,
    5,
    2.5
  );

  doc.setFont("Helvetica", "bold");
  doc.text(`Producto:`, leftX, baseY + spacing * 4);
  doc.setFont("Helvetica", "");
  doc.text(`Policarbonato Asférico`, leftX + 45, baseY + spacing * 4);

  doc.setFont("Helvetica", "bold");
  doc.text(`Fecha:`, leftX, baseY + spacing * 5);
  doc.setFont("Helvetica", "");
  doc.text(`${fechaHoy} ${horaHoy}`, leftX + 45, baseY + spacing * 5);

  doc.setDrawColor(150);
  doc.setLineWidth(0.2);
  doc.line(15, baseY + spacing * 6, pageWidth - 15, baseY + spacing * 6);

  doc.setFontSize(9);
  doc.setFont("Helvetica", "italic");
  doc.text(
    "Personaliza tus lentes asféricos con la mejor tecnología del mundo.",
    pageWidth / 2,
    baseY + spacing * 7,
    { align: "center" }
  );

  doc.setFont("Helvetica", "bold");
  doc.text("Solo en Opticenter Vision", pageWidth / 2, baseY + spacing * 8, {
    align: "center",
  });

  // WhatsApp
  const iconX = 10;
  const iconY = pageHeight - 10;
  const iconW = 5;
  const iconH = 5;

  doc.addImage(iconoWhatsapp, "PNG", iconX, iconY, iconW, iconH);

  doc.setFontSize(8);
  doc.setFont("Helvetica", "");
  doc.text("+51 934 817 012", iconX + iconW + 2, iconY + 4.2);

  doc.setFontSize(7);
  doc.setFont("Helvetica", "italic");
  doc.text("*Imágenes Referenciales", pageWidth - 10, pageHeight - 5, {
    align: "right",
  });

  // Abrir PDF
  if (modo == "sube") {
    return doc.output("blob");
  } else {
    window.open(doc.output("bloburi"));
  }
};
